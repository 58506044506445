// custom-list
.custom-list {
	padding: 0!important;
	list-style: none!important;

	li {
		position: relative;
		padding: 0 0 0 1.5rem;

		&::before {
			content: "\f00c";
			position: absolute;
			top: 0;
			left: 0;
			font-family: $font-awesome;
			color: $primary;
			font-weight: 900;
		}
	}
}

// custom-list-extra
.custom-list-extra {
	padding: 0!important;
	list-style: none!important;

	li {
		position: relative;
		padding: 0 0 0 1.5rem;

		&::before {
			content: "\f058";
			position: absolute;
			top: 0;
			left: 0;
			font-family: $font-awesome;
			color: $primary;
			font-weight: 400;
		}
	}
}

.usp-list {
	ul {
		display: flex;
		width: 100%;
		justify-content: center;
		flex-flow: row wrap;
		gap: 1rem;
		@include media-breakpoint-down(md) {
			justify-content: flex-start;
			flex-flow: column;
		}
		li {
			color: $white;
			font-weight: 600;
			font-size: 15px;
			max-width: 12.5rem;
			white-space: pre-line;
			display: flex;
			align-items: center;
			line-height: 1.1rem;
			&::before {
				content: "\f00c";
				margin-right: 1rem;
				font-family: $font-awesome;
				color: $white;
				font-size: 30px;
				font-weight: 400;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 3rem;
				height: 3rem;
				aspect-ratio: 1/1;
				border-radius: 50%;
				background-color: rgba(#fff, 0.4);
				@include media-breakpoint-down(md) {
					width: 1.8rem;
					height: 1.8rem;
					font-size: 15px;
				}
			}
			@include media-breakpoint-down(md) {
				max-width: 100%;
			}
		}
	}
}

.app-icons {
	.wysiwyg:nth-child(1) {
		position: relative;
		width: 60%;
		@include media-breakpoint-down(md) {
			width: 100%;
		}
	}
}

ul.app-icons {
	display: flex;
	list-style: none;
	flex-flow: row wrap;
	padding: 0 !important;
	width: calc(60% + 150px);
	li:nth-child(3) {
		align-self: flex-end;
		justify-self: flex-end;
		margin-left: auto;
		@include media-breakpoint-down(md) {
			width: 100%;
		}
	}
	li a {
		display: block;
		margin-right: 0.5rem;
		span {
			display: none;
		}
		&[title="android"] {
			content: url("/images/android.svg");
			height: 41px;
		}
		&[title="apple"] {
			content: url("/images/apple.svg");
			height: 41px;
		}

		&[title="qr"] {
			width: 125px;
			aspect-ratio: 1/1;
			margin-right: 0;
			transform: translateY(-50%);
			background-image: url("/images/qr_app.png");
			@include media-breakpoint-down(md) {
				transform: none;
				margin-top: 0.5rem;
			}
		}
	}
	@include media-breakpoint-down(md) {
		margin-bottom: 6vh;
	}
}
