// btn
.btn {
	box-shadow: $shadow;
	font-weight: 500;
	border-radius: 28px;
	padding: 0.5rem 1rem;

	// btn-primary
	&.btn-primary {
		background-color: $yellow;
		color: $black;
		border: 0;
		min-width: 175px;
		&:hover {
			background-color: rgba($yellow, 0.75);
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		&:hover {
		}
	}

	// btn-secondary
	&.btn-secondary {
		&:hover {
		}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		&:hover {
		}
	}
}

// card-btn
.card-btn {
	@extend .btn;
	@extend .btn-primary;
	&:hover {
		color: black !important;
	}
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;
	text-decoration: none;
	font-weight: 600;
&:hover{
&::before{
transform: translateX(-.25rem);
}
}
	&::before {
		content: "\f0d9";
		position: absolute;
		left: 0;
		font-weight: 900;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}
