// footer
.footer {
	background-color: $footer-blue;
	.footer-intro-section {
		@include media-breakpoint-down(md) {
			padding-bottom: 12vh;
		}
		position: relative;
		background-image: url("/images/footer-intro-bg.svg");
		background-position: top right -15%;
		background-repeat: no-repeat;
		color: $white !important;
		.container-one-column {
			text-align: center;
			@include media-breakpoint-down(md) {
				text-align: left;
			}
			h3,
			h4,
			h5,
			h6 {
				font-weight: 200;
				font-family: $font-family-secondary !important;
				color: $white;
			}
			h2,
			h1 {
				font-family: $font-family-primary;
				color: $white;
			}

			.title,
			.subtitle {
				font-size: 36px;
				color: $white !important;
			}
			margin: 6vh auto;
		}
		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			height: 6vh;
			width: 100%;
			background-image: url("/images/ill bottom wit.svg");
			background-repeat: no-repeat;
			background-position: top;
			background-size: cover;
			@include media-breakpoint-down(md) {
				top: -1px;
			}
		}
		.app-footer-text {
			max-width: 300px;
		}
	}
	.footer-partner-section {
		position: relative;
		background-color: $white;
		padding: 0 0 12vh 0;
		ul.footer-partners {
			display: flex;
			flex-flow: row wrap;
			gap: 1rem;
			justify-content: center;
			span.list-item-title {
				display: none;
			}
			li {
				transition: 0.35s ease;
				&:hover {
					opacity: 0.8;
					transition: 0.35s ease;
				}
			}
		}
		&::before {
			content: "";
			position: absolute;
			top: -5rem;
			left: 0;
			height: 5rem;
			width: 100%;
			background-image: url("/images/ill top2 wit.svg");
			background-repeat: no-repeat;
			background-position: top;
			background-size: cover;
		}
	}
	.footer-links-section {
		position: relative;
		padding-bottom: 0;
		.container-one-column:nth-child(1) {
			margin: 3vh auto;
		}
		.h6 {
			font-weight: 200;
			font-family: $font-family-secondary !important;
		}

		h3,
		h2,
		h1 {
			font-family: $font-family-primary;
			color: $white;
		}

		background-color: $footer-green;
		p,
		a:not(.btn),
		h6,
		h5,
		h4,
		h3,
		h2,
		h1 {
			color: $white !important;
		}
		.link {
			text-decoration: none;
			:hover {
				text-decoration: underline;
			}
		}
		.vocabulary-term-list {
			i {
				font-size: 24px;
				margin-right: 0.5rem;
				color: $white;
				transition: 0.35s ease-in-out;
				&:hover {
					color: rgba($white, 0.55);
					transition: 0.35s ease-in-out;
				}
			}
			span.list-item-title {
				display: none;
			}
		}
		&::before {
			content: "";
			position: absolute;
			top: -6vh;
			left: 0;
			height: 6vh;
			width: 100%;
			background-image: url("/images/ill top2 dgroen.svg");
			background-repeat: no-repeat;
			background-position: top;
			background-size: cover;
		}
		@include media-breakpoint-down(md) {
			padding: 0 0 3vh 0;
		}
	}
	.footer-socket {
		padding-top: 6vh;
		ul.list {
			display: flex;
			flex-flow: row;
			align-items: center;
			justify-content: flex-start;
			gap: 1rem;
			padding: .75rem 0;
			li a {
				font-size: 14px;
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}
