.cookie-consent-banner {
	bottom: 5%;
	left: 50%;
	transform: translateX(-50%);
	width: 80vw;
	max-width: 1184px;
	border: 5px solid #006FBA;
	border-radius: 48px;
	padding: 170px 80px 25px;
	background: #fff;
	@media(max-width: 767px) {
		padding: 170px 10px 25px;
		bottom: auto;
		top: 50%;
		transform: translate(-50%, -50%);
		max-height: 80vh;
	}

	> .container {
		@media(max-width: 767px) {
			max-height: 400px;
			overflow: auto;
		}
	}

	.cookie-container {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		text-align: center;

		&:before {
			content: '';
			background: url('/images/cookiemelding.png');
			max-width: 100%;
			display: block;
			position: absolute;
			left: 50%;
			top: 25px;
			height: 130px;
			width: 100%;
			transform: translateX(-50%);
			z-index: 1000000;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: 50%;
		}

		.cookie-container-left {
			.cookie-title {
				display: none;
			}

			.cookie-description {
				font-size: 15px;

				p {
					color: #000;
				}

				ul {
					list-style: none;
					padding-left: 0;

					li {
						strong {
							color: #000;
						}
					}
				}
			}

			.cookie-btn {
				font-size: 15px;
				margin-bottom: 27px;
				display: block;
			}
		}

		.cookie-container-right {
			padding-left: 0;
			border: none;

			.cookie-close {
				text-decoration: none;

				.text {
					margin: 0;
					color: #003254;
					background: #FFC300;
					border-radius: 22px;
					border: 1px solid #FFC300;
					font-size: 15px;
					padding: 6px 57px;
					text-decoration: none;
				}

				&:hover {
					.text {
						color: #FFC300;
						background: transparent;
					}
				}

				.icon {
					display: none;
				}
			}
		}
	}
}
