&.home {
	@include media-breakpoint-down(md) {
		.owl-container {
			display: flex;
			justify-content: flex-start !important;
			align-items: center !important;
			transform: translateY(-20%);
			.owl-title {
				font-size: 32px !important;
			}
			.owl-subtitle {
				font-size: 22px !important;
			}
		}
	}
	.owl-title,
	.owl-subtitle,
	.owl-description,
	.owl-btn {
		display: initial !important;
	}
}

// eyecatcher
&.bundle-overview:not(.accommodation-category-overview):not(
		.accommodation-search-book
	),
&.bundle-category:not(.accommodation-category-detail),
&.service-detail,
&.blog-post-overview,
&.blog-post-detail,
&.contact-page,
&.accommodation-category-overview,
&.accommodation-category-detail,
&.accommodation-detail,
&.accommodation-search-book,
&.two-column-page,
&.one-column-page,
&.landing-page {
	.eyecatcher {
		padding: 0;
		position: relative;
		.owl-carousel {
			&:after {
				content: "";
				position: absolute;
				bottom: -6vh !important;
				left: 0;
				height: 12vh;
				width: 100%;
				background-image: url("/images/ill top wit.svg") !important;
				background-size: cover;
				background-repeat: no-repeat;
				z-index: 900;
				background-position: top;
			}
		}
	}
}
&.home {
	.eyecatcher:not(.eyecatcher-content) .owl-carousel {
		position: relative;
		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			height: 12vh;
			width: 100%;
			background-image: url("/images/ill lijn top lgroen.svg");
			background-size: 100vw;
			background-position: bottom;
			background-repeat: no-repeat;
			z-index: 1000;
			transform: translateY(1px);
			@include media-breakpoint-down(md) {
				background-position: bottom;
				bottom: 0;
				height: 6vh;
				background-size: cover;
			}
		}
		.item {
			.owl-container,
			.owl-caption {
				z-index: 2!important;
			}
		}
	}
}
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
	.owl-caption {
		height: 45vh;
		z-index: 1000;
		display: flex;
		flex-flow: column;
		align-items: center;
		justify-content: center;

		.owl-title,
		.owl-subtitle,
		.owl-description,
		.owl-btn {
			display: none;
		}

		.owl-title {
			font-size: 48px;
			text-shadow: 1px 1px 2px rgba(78, 78, 78, 0.5);
		}

		.owl-subtitle {
			font-size: 36px;
			font-family: $font-family-secondary;
			text-shadow: 1px 1px 2px rgba(78, 78, 78, 0.5);
		}
	}
	// .owl-dots {
	// 	display: none;
	// }
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 85vh;
	min-height: 540px;
	max-height: 1080px;
	margin: 0 0;
	padding: 0;
	position: relative;
	@include media-breakpoint-up(sm) {
		height: 85vh;
	}
}

.map {
	position: absolute;
	right: 6vw;
	bottom: 35%;
	z-index: 800;
	max-width: 120px;
	max-height: 119px;
	margin: 0 0 !important;
	@include media-breakpoint-down(md) {
		bottom: 20vh;
		right: 10vw;
	}
	.map-image {
		position: relative;
		margin: 0 0 !important;
		&::before {
			content: "\f3c5";
			font-family: $font-awesome;
			font-size: 24px;
			font-weight: 900;
			position: absolute;
			color: $white;
			left: 70%;
			top: 25%;
		}
		img {
			max-width: 120px !important;
		}
	}
	&:hover {
		.map-image {
			&::before {
				animation: bounce 1s ease-in-out infinite;
			}
		}
	}
	.link {
		position: absolute;
		width: 100%;
		top: 0;
		height: calc(100% + 10px);
		display: flex;
		align-items: flex-end;
		justify-content: center;
		a {
			// content: "Route";
			color: $white;
			font-weight: 600;
			position: absolute;
			text-decoration: none;
		}
	}
}

@keyframes bounce {
	0% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(5px);
	}
	0% {
		transform: translateY(0);
	}
}

.eyecatcher-content {
	.owl-stage-outer,
	.owl-stage,
	.item {
		height: 70vh;
		margin: 0 0;

		// &::before {
		// 	content: "";
		// 	position: absolute;
		// 	bottom: 0;
		// 	left: 0;
		// 	height: 100%;
		// 	width: 100%;
		// 	background-image: linear-gradient(rgba(#000, 0), rgba(#000, 0.35));
		// }

		.owl-container {
			z-index: 990;
		}
	}
	.owl-caption {
		height: 45vh;
		z-index: 1000;
		display: flex;
		flex-flow: column;
		align-items: center;
		justify-content: flex-end;

		.owl-title,
		.owl-subtitle {
			text-shadow: 1px 1px 2px rgba(78, 78, 78, 0.5);
		}

		.owl-title {
			font-size: 48px !important;
			font-family: $font-family-secondary !important;
		}
		.owl-description {
			font-family: $font-family-primary !important;
		}

		.owl-subtitle {
			font-size: 36px;
			font-family: $font-family-primary !important;
		}
	}
	.owl-dots {
		transform: translateY(-6vh);
		button {
			transform: scale(1.4);
			margin: 0.25rem;
		}
	}
}
