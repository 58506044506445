// header
.header {
	section.news-ticker-section {
		@keyframes newsScroll {
			to {
				transform: translateX(-100%);
			}
		}
		padding: 0 !important;
		margin-bottom: 15px;
		.container-fluid {
			margin: 0;
			padding: 0;

			div.ticker {
				display: flex;
				flex-flow: row;
				overflow: hidden;
				justify-content: space-between;

				ul.ticker {
					display: flex !important;
					justify-content: center !important;
					flex-flow: row nowrap !important;
					align-items: center;
					padding: 5px 0;
					gap: 5rem;
					min-width: 100vw;
					li {
						display: flex;
						justify-content: space-around;
						align-items: center;
						flex-flow: row nowrap;
						flex-wrap: nowrap !important;
						gap: 1rem;
						font-weight: 600;
						min-width: max-content;
						padding-left: 1rem;

						@include media-breakpoint-down(lg) {
						}
						&::before {
							content: "\f005";
							font-family: $font-awesome;
							color: #ffc221;
						}
					}
					&.active {
						animation: newsScroll 30s infinite linear;
						justify-content: space-around;
						min-width: unset;
						@include media-breakpoint-down(lg) {
							animation: newsScroll 30s infinite linear;
						}
						li {
							&:nth-child(1) {
								padding-left: 5rem;
							}
						}
					}
					&.active-mobile {
						@include media-breakpoint-down(lg) {
							
							justify-content: space-around;
							min-width: unset;
							animation: newsScroll 32s infinite linear;
						}
					}
				}
				&:hover {
					ul.active {
						animation-play-state: paused;
					}
				}
			}
		}
	}
	.top {
		padding: 0;
	}
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	transition: 0.5s ease;
	padding: 0 0 15px 0;
	background: -webkit-gradient(
		linear,
		top,
		bottom,
		color-stop(60%, #fff),
		color-stop(100%, transparent)
	);
	/* Chrome, Safari4+ */
	background: -webkit-linear-gradient(top, #fff 60%, transparent 100%);
	/* Chrome10+, Safari5.1+ */
	background: -moz-linear-gradient(top, #fff 60%, transparent 100%);
	/* FF3.6+ */
	background: linear-gradient(to bottom, #fff 60%, transparent 100%);
	/* W3C */
	@include media-breakpoint-up(xl) {
		.column {
			padding-left: 15px;
			padding-right: 15px;
		}
	}
	&.fullBg {
		background: #fff;
	}
	&.sticky {
		background: $white;
		box-shadow: $shadow;
		section:not(.news-ticker-section) {
			.container-fluid {
				.container-holder {
					> .column {
						max-height: 6vh !important;
						height: 6vh !important;
						transition: 0.35s ease;
					}
				}
			}
		}
		.logo {
			transform: scale(0.5);
		}
	}

	.container-fluid {
		.container-holder {
			> .column {
				@extend .align-items-center;
				transition: 0.35s ease;
				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
					justify-content: flex-end;
					> div:not(.logo) {
						margin-right: 0.15rem;
					}
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// logo
	.logo {
		@include media-breakpoint-down(md) {
			flex-basis: auto;
			margin-top: 15px;
			margin-right: auto;
			display: flex;
			justify-content: center;
		}

		@include media-breakpoint-up(lg) {
			margin-right: auto;
		}

		a {
			display: block;

			img {
				@include media-breakpoint-down(md) {
					max-height: 60px;
				}

				max-height: 95px;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		@include media-breakpoint-down(md) {
			order: -1;
			.navbar-toggler {
				aspect-ratio: 1/1;
				padding: 0.65rem !important;
				min-width: 3rem !important;
				display: flex;
				justify-content: center;
				background-color: $green !important;
				transition: 0.35s ease;
				.navbar-toggler-icon {
					margin-right: unset !important;
					font-size: 16px !important;
				}
				&[aria-expanded="true"] {
					transform: rotate(90deg);
					transition: 0.35s ease;
				}
			}
		}

		.navbar-toggler {
			display: flex !important;
			align-items: center;
			margin-right: 0.65rem;
			border: none;
			color: $white;
			font-size: 14px;
			font-weight: 300;
			font-family: $font-family-primary;
			background-color: $green;
			padding: 0.5rem 1rem;
			border-radius: 28px;
			.navbar-toggler-label {
				font-weight: 600;

				@include media-breakpoint-down(md) {
					display: none;
				}
			}
			i {
				transition: 0.15s ease;
				min-width: 1.5rem;
				aspect-ratio: 1/1;
				@include media-breakpoint-down(md) {
					min-width: unset;
				}
			}
			&[aria-expanded="true"] {
				i {
					transform: rotate(90deg);
					transition: 0.15s ease;
					&::before {
						content: "\f00d";
					}
				}
			}

			.navbar-toggler-icon {
				margin-right: 10px;
				font-size: 24px;
			}

			&:hover {
				background-color: rgba($footer-green, 0.95);
			}
		}
	}

	// book-button
	.header-btn a {
		display: block;
		padding: 0.5rem 1rem;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $blue;
		color: $white;
		text-decoration: none;
		font-weight: 600;
		border-radius: 28px;
		margin-right: 1rem;

		&:hover {
			background-color: rgba($blue, 0.8);
		}

		@include media-breakpoint-down(md) {
			order: 3;
			max-width: 26px;
			max-height: 26px;
			height: 26px;
			width: 26px;
			border-radius: 50%;
			aspect-ratio: 1/1;
			padding: 0;
			margin: 0;

			&::after {
				content: "\f073";
				font-family: $font-awesome;
			}

			span {
				display: none;
			}
		}
	}

	// site-switcher
	.site-switcher {
		&.mobile-only {
			.dropdown-toggle {
				&::after {
					display: none;
				}
			}
			@include media-breakpoint-up(lg) {
				display: none;
			}
		}
		&.desktop-only {
			@include media-breakpoint-down(md) {
				display: none;
			}
		}
		order: 4;
		li {
			transition: 0.35s ease;
			&:hover {
				transform: translateY(2px);
				transition: 0.35s ease;
			}
		}
	}

	.menu-overlay {
		padding: unset;
		&.collapse:not(.show) {
			display: none;
		}

		.container {
			@include media-breakpoint-down(lg) {
				max-width: 100% !important;
			}
		}
		// menu
		.menu {
			display: flex;

			@include media-breakpoint-down(lg) {
				.navbar-nav {
					width: 100%;
					margin-top: 15px;

					> .nav-item {
						> .nav-link {
							color: $black;
							font-size: 20px;
							font-weight: 700;
							font-family: $font-family-primary;
						}

						border-top: 1px solid rgba($black, 0.1);

						&:first-of-type {
							border-top: none;
						}
					}

					.nav-item {
						.nav-link {
							color: $black;

							&:hover {
								color: $primary;
							}

							&.dropdown-toggle {
								&::after {
									content: "\f107";
									position: absolute;
									top: 0;
									right: 0;
									color: $black;
									font-size: 20px;
									line-height: 40px;
									font-family: $font-awesome;
									border: none;
									margin-left: 0;
								}
							}
						}

						&.active {
							.nav-link {
								color: $primary;
							}
						}

						.dropdown-menu {
							position: static !important;
							transform: none !important;
							margin: 0 0 0 20px;
							padding: 0;
							border: none;
							box-shadow: none;
							background: transparent;
						}
					}
				}
			}

			@include media-breakpoint-up(xl) {
				margin: 6vh 0;

				.navbar-nav {
					width: 100%;
					flex-wrap: wrap;
					flex-direction: row;
					justify-content: space-evenly;

					> .nav-item {
						margin-bottom: 30px;
						padding-right: 30px;

						> .nav-link {
							color: $black;
							font-size: 20px;
							font-weight: 700;
							font-family: $font-family-primary;

							&.dropdown-toggle {
								pointer-events: none;
							}

							&::after {
								display: none;
							}

							&:hover {
							}
						}
					}

					.nav-item {
						.nav-link {
							padding: 0;
							color: $black;

							&:hover {
								color: $primary;
							}
						}

						.dropdown-menu {
							position: static !important;
							transform: none !important;
							display: block;
							margin: 0;
							padding: 0;
							border: none;
							box-shadow: none;
							background: transparent;
							.nav-item {
								margin-top: 5px;

								&.active {
									.nav-link {
										color: $primary;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	// social icons
	.vocabulary-term-list.social-header {
		margin-right: 0.55rem;

		li {
			transition: 0.35s ease;
			i {
				color: #003254;
				font-size: 18px;
				margin: auto 0.45rem;

				@include media-breakpoint-down(md) {
					margin: 0.25rem;
				}
			}

			span,
			span.list-item-title {
				display: none;
			}
			@include media-breakpoint-down(md) {
				&.hide {
					display: none;
				}
			}
			&:hover {
				transform: translateY(2px);
				transition: 0.35s ease;
			}
		}
	}
	@include media-breakpoint-down(md) {
		background-color: $white;
	}
}
// home
&.home {
	.header {
		@include media-breakpoint-up(xl) {
			position: fixed;
		}
	}
}

.header {
	.page-block.footer-text {
		margin-top: 14px;
		font-size: 20px;
		margin-right: 12px;
	}
}
