.accommodation-cards,
.faciliteiten-slider {
	.card {
		background: none;
		border: 0;
		overflow: visible;

		div.card-image {
			box-shadow: $shadow;

			&::after {
				display: none;
			}
		}
		.card-image,
		img {
			aspect-ratio: 1/1;
		}
	}
}

// .faciliteiten-slider {
// 	.card {
// 		background: none;
// 		border: 0;
// 		overflow: visible;

// 		div.card-image {
// 			box-shadow: 4px 4px 25px rgba($black, 0.15);
// 			&::after {
// 				display: none;
// 			}
// 		}

// 		.card-image,
// 		img {
// 			aspect-ratio: 1/1;
// 		}
// 	}
// }

.card {
	border: 0;
	border-radius: 14px;
	background-color: #ebf3e1;
	div.card-image {
		position: relative;
		&::after {
			position: absolute;
			content: "";
			height: 2rem;
			width: 100%;
			bottom: 0;
			left: 0;
			background-image: url("/images/card-wave.svg");

			background-size: cover;
		}
	}
	.card-title {
		color: $green !important;
	}

	.card-subtitle {
		font-size: $h6-font-size;
	}
}

&.accommodation-category-overview {
	.card-subtitle {
		display: none;
	}
}
