// mini-sab
&.home {
	.mini-sab {
		@include media-breakpoint-down(md) {
			height: 18vh;
		}
		.container .container-holder {
			background-color: $white;
			bottom: 14vh;
			@include media-breakpoint-down(md) {
				bottom: -18vh;
				padding: 0.5rem;
			}
		}
		.mini-search-book {
			.heading {
				color: $blue-dark !important;
			}
		}
	}

	.thr-search-engine-type,
	.thr-search-engine-dates {
		&::before {
			color: $dark !important;
		}
	}
}

.mini-sab {
	background-color: $green;
	margin: 0 0;
	padding: 0 0;
	.container {
		position: relative;
		@extend .d-flex;
		align-items: center;
		justify-content: center;

		.container-holder {
			z-index: 900;
			position: relative;
			margin: 15px 0 0 0;
			padding: 15px 1rem;
			border-radius: 45px;
			background: $green;
			box-shadow: 3px 3px 6px rgba($black, 0.15);
			color: $blue-dark;
			.column {
				display: flex;
				flex-flow: row;
				align-items: center;
				@include media-breakpoint-down(md) {
					flex-flow: column;
					// align-items: baseline;
					// justify-content: center;
					padding: 0;
				}
			}

			@include media-breakpoint-down(md) {
				padding: 0.5rem;
				border-radius: 20px;
			}
			// below eyecatcher
			// @include media-breakpoint-up(lg) {
			// 	margin-top: -50px;
			// 	padding-left: 30px;
			// 	padding-right: 30px;
			// }

			// above eyecatcher

			position: absolute;
			left: auto;
			right: auto;
			bottom: -6vh;
			@include media-breakpoint-down(md) {
				bottom: -15vh;
				width: 95%;

				> div {
					@include media-breakpoint-down(md) {
						padding-left: 0 !important;
					}
					> div {
						display: flex;
						justify-content: flex-start;
					}
				}
			}
			.mini-search-book {
				display: flex;
				flex-flow: row;
				align-items: center;
				.heading {
					margin-right: 1.5rem;
					font-size: 30px;
					font-family: $font-family-secondary !important;
					font-weight: 600;
					color: $white;

					@include media-breakpoint-down(md) {
						margin-right: 0;
						width: 100%;
						text-align: center;
					}
				}
				@include media-breakpoint-down(md) {
					display: flex;
					justify-content: flex-start;
					flex-flow: column;
					.ng-isolate-scope {
						@include media-breakpoint-down(md) {
							padding: 0 !important;

							width: 100% !important;
						}
					}
				}

				.btn-primary {
					order: 3;
					box-shadow: $shadow !important;
					font-weight: 600 !important;
					border-radius: 28px !important;
					padding: 0.5rem 1rem !important;
					background-color: $yellow !important;
					color: $black !important;
					border: 0 !important;
					min-width: 126px !important;
					max-height: 36px !important;
					font-size: 15px !important;
					margin-top: 10px;
					&:hover {
						background-color: rgba($yellow, 0.75) !important;
					}
					i {
						display: none;
					}
				}
				.thr-range-picker,
				.thr-select {
					position: relative;
					font-family: $font-family-primary !important;
					border: 1px solid #fff;
					&:hover {
						border: 1px solid #ccc;
					}
				}
				.thr {
					@include media-breakpoint-down(md) {
						padding: 0.5rem !important;

						display: flex;
						justify-content: flex-start;
						width: 100% !important;
					}
					display: flex;
					justify-content: flex-end;
					.thr-search-engine {
						width: unset !important;
						.form-group {
							padding: unset;
							@include media-breakpoint-down(md) {
								margin-top: 1rem;
							}
						}
						width: 100%;
						.thr-search-engine-main {
							display: flex;
							flex-flow: row;
							justify-content: flex-end !important;
							align-items: center;

							& > div {
								border: 0;
								width: 100%;
								height: 3rem;
								display: flex;
								margin-right: 1rem;
								align-items: flex-end;
								@include media-breakpoint-down(md) {
									margin-right: 0;
								}
							}
							@include media-breakpoint-down(md) {
								flex-flow: column;
								width: 100%;
							}
						}
						.thr-search-engine-pers {
							display: none !important;
						}

						.thr-search-engine-type {
							order: 1;
							.thr-select {
								height: 36px;
								width: 220px;
								border-radius: 3px;
								background-color: #f2f2f2;
								@include media-breakpoint-down(md) {
								}
								i {
									&::before {
										color: $green;
									}
								}
								.thr-select-placeholder {
									padding: 0.5rem;
								}
							}
							@include media-breakpoint-down(md) {
							}
						}
						.thr-search-engine-dates {
							order: 2;
							.thr-range-picker {
								height: 36px;
								width: 220px;
								border-radius: 3px;
								background-color: #f2f2f2;
								padding: 0.5rem;
								i.fa-calendar-alt {
									&::before {
										color: $green;
									}
								}
							}
							@include media-breakpoint-down(md) {
							}
						}
					}
				}
			}
		}
	}
}
