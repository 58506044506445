// bg-*
// =========================================================================
section {
	padding: 6vh 0;
	&.bg-light,
	&.bg-dark {
		padding: 6vh 0;
	}
	&.bg-blue {
		background-color: $blue-dark;
	}
	.container {
		@include media-breakpoint-down(md) {
			.container-holder {
				margin-right: unset;
				margin-left: unset;
			}
		}
	}
}

// lead-section
// =========================================================================
&.landing-page {
	.lead-section {
		@include media-breakpoint-down(md) {
			padding-top: 18vh;
		}
	}
}
&.accommodation-category-overview,
&.accommodation-category-detail,
&.accommodation-detail,
&.accommodation-search-book {
	.lead-section {
		padding-top: 12vh;
	}
}

.lead-section {
	padding-top: 12vh;
	padding-bottom: 0 !important;
	@include media-breakpoint-down(md) {
		padding-top: 18vh;
	}

	.container {
		.container-holder {
			text-align: left;
			.title,
			.page-title {
				> * {
					color: $green !important;
				}
			}
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {
		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			// @extend .justify-content-start;
			@extend .list-unstyled;

			li {
				margin: 0 15px 0 0;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}
	}
	@include media-breakpoint-down(md) {
		padding-bottom: 0;
	}
}

// content-section
// =========================================================================
.content-section {
	position: relative;
	padding: 0 0;
	.container-fluid {
		padding-left: 0px;
		padding-right: 0px;
		overflow-x: hidden;
	}

	// intro/outro
	.intro,
	.outro {
		max-width: 750px;

		.container-holder {
			text-align: center;
		}
	}

	.intro {
		.container-holder {
			margin-bottom: 30px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	.outro {
		.container-holder {
			margin-top: 30px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}
}

// banner-section
// =========================================================================
.banner-section {
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	padding: 3vh 0 3vh 0;
	background-image: url("/images/groep links lgroen.svg"),
		url("/images/groep rechts dgroen.svg");
	background-repeat: no-repeat;
	background-size: 30%;
	background-position: top 50% left -10%, top 5% right -10%;
	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	background-image: url("/images/groep links lgroen.svg"),
		url("/images/groep rechts dgroen.svg");
	background-repeat: no-repeat;
	background-size: 20%, 30%;
	background-position: top 50% left -30%, top 5% right -10%;
	margin: 0 0;
	@include media-breakpoint-down(md) {
		margin: 0 0;
	}
}

// gallery-section
// =========================================================================
.gallery-section {
	background-image: url("/images/groep links lgroen.svg"),
		url("/images/groep rechts dgroen.svg");
	background-repeat: no-repeat;
	background-size: 30%;
	background-position: top 5% left -10%, top 50% right -10%;
	padding: 3vh auto;
	.gallery-horizontal {
		justify-content: flex-start;
	}
}

// booking-section
// =========================================================================
.booking-section {
	background-image: url("/images/groep links lgroen.svg"),
		url("/images/groep rechts dgroen.svg");
	background-repeat: no-repeat;
	background-size: 30%;
	background-position: top 50% left -10%, top 5% right -10%;
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}

.usp-section {
	padding: 6vh 0;
	background-color: $green;
	margin: -6vh 0 !important;

	z-index: 100;
	.container {
		max-width: 50%;
		@include media-breakpoint-down(md) {
			max-width: 100%;
		}
	}
	@include media-breakpoint-down(md) {
		padding: 15vh 0 6vh 0;
	}
}

.home-blokken-section {
	position: relative;

	padding: 12vh 0 0 0;
	background-color: $green;
	.container .column {
		color: $white !important;
		h6,
		h5,
		h4,
		h3,
		h2,
		h1,
		a:not(.btn) {
			color: $white;
		}
	}
	.container-holder {
		> div {
			padding: 0.5rem;
		}
		@include media-breakpoint-down(md) {
			flex-flow: column;
			gap: 3vh;
		}
	}
	a {
		&:hover {
			text-decoration: none;
		}
	}
	.news-slider {
		.card {
			background: transparent;
			.card-image {
				display: none;
			}
			.card-caption {
				display: flex;
				flex-flow: column;
				flex: unset;
				.card-subtitle {
					order: -1;
				}
				.card-title,
				.card-description {
					color: #fff !important;
				}
			}
			.card-btn {
				padding: 0;
				background: transparent;
				color: #fff;
				box-shadow: none;
				text-align: left;
				text-decoration: underline;
				&:hover {
					text-decoration: none;
				}
			}
		}
		.owl-dots {
			button {
				span {
					border: 1px solid $white !important;
				}
				&.active {
					span {
						background: $white !important;
					}
				}
			}
		}
	}
}

.about-section {
	background-image: url("/images/about-bg-tl.svg");
	background-position: top -2vh left -25%;
	background-repeat: no-repeat;
	padding: 15vh 0 15vh 0 !important;
	position: relative;
	.subtitle {
		font-family: $font-family-secondary;
		font-size: 36px;
		color: $dark;
		@include media-breakpoint-down(md) {
			font-size: 24px !important;
		}
	}
	.title h6,
	h5,
	h4,
	h3,
	h2,
	h1 {
		color: $blue-dark;
		font-size: 48px;
	}
	.container-holder {
		@include media-breakpoint-down(md) {
			flex-flow: column;
		}
	}
	.column {
		&.one {
			flex: 0 0 70% !important;
			max-width: 70%;
			.subtitle {
				color: $black;
				font-size: 24px;
				font-weight: 200;
			}
			@include media-breakpoint-down(md) {
				max-width: 100%;
				width: 100%;
			}
		}
		&.two {
			flex: 0 0 30% !important;
			max-width: 30%;
			display: flex;
			justify-content: center;
			align-items: center;
			@include media-breakpoint-down(md) {
				max-width: 100%;
				width: 100%;
				padding-top: 3vh;
			}
		}
	}
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		height: 8vh;
		width: 100%;
		background-image: url("/images/ill lijn bottom lgroen.svg");
		background-repeat: no-repeat;
		background-size: cover;
		z-index: 900;
		background-position: top;
		@include media-breakpoint-down(md) {
			top: -1px;
		}
	}
	@include media-breakpoint-down(md) {
		background-position: top 0 left 150%;
		padding-top: 24vh !important;
	}
}

.omgeving-section {
	background-image: url("/images/omgeving-bg-center.svg"),
		url("/images/omgeving-bg-center.svg");
	background-position: center left, center right;
	background-repeat: no-repeat;
	position: relative;
	padding: 0 0 12vh 0 !important;
	.subtitle {
		font-family: $font-family-secondary;
	}
	.title,
	.subtitle {
		font-size: 36px;
		@include media-breakpoint-down(md) {
			font-size: 24px !important;
		}
	}
	.container {
		.column {
			display: flex;
			justify-content: center;
		}
	}
	.y-offset {
		img {
			box-shadow: 0 0 20px rgba($black, 0.5);
			transform: translateY(-15%);
		}
		@include media-breakpoint-down(md) {
			img {
				transform: none;
			}
			transform: translateY(-20%);
		}
	}
	.y-offset-negative {
		img {
			box-shadow: 0 0 20px rgba($black, 0.5);
			transform: translateY(6vh);
		}
		@include media-breakpoint-down(md) {
			transform: translateY(-6vh);

			.container-holder {
				flex-flow: column-reverse;
				padding-bottom: 6vh !important;
			}

			img {
				transform: none;
			}
		}
	}
	&::after {
		content: "";
		position: absolute;
		top: -6vh;
		left: 0;
		height: 12vh;
		width: 100%;
		background-image: url("/images/ill top lgroen.svg");
		background-repeat: no-repeat;
		background-size: 100vw;
		z-index: -1;
		@include media-breakpoint-down(md) {
			background-size: cover;
		}
	}
	&::before {
		content: "";
		position: absolute;
		bottom: -1px;
		left: 0;
		height: 10vh;
		width: 100%;
		background-image: url("/images/ill top wit.svg");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: bottom;
		@include media-breakpoint-down(md) {
			height: 6vh;
		}
	}
	@include media-breakpoint-down(md) {
		margin-top: 6vh;
		padding: 0 0 0 0 !important;
	}
}

.accommodations-section {
	padding: 0 0 9vh 0;
	position: relative;

	h5.subtitle {
		font-family: $font-family-secondary !important;
		font-size: 36px !important;
		font-weight: 200;
	}
	.title {
		h2 {
			color: $blue-dark;
			font-size: 48px;
			@include media-breakpoint-down(md) {
				font-size: 36px;
			}
		}
	}
	.container {
		margin: 3vh auto;
		&.center-text {
			text-align: center;
			@include media-breakpoint-down(md) {
				text-align: left;
			}
		}
	}
	@include media-breakpoint-down(md) {
		padding: 0 0 3vh 0;
	}
}
.faciliteiten-section {
	background-image: url("/images/faciliteiten-bg-tr.svg");
	background-position: top 6vh right -25%;
	background-repeat: no-repeat;
	position: relative;
	.container:nth-child(1) {
		margin: 6vh auto;
	}

	.subtitle {
		font-family: $font-family-secondary;
	}

	.title,
	.subtitle {
		font-size: 36px;
		color: $white !important;
	}

	.container-fluid {
		.column {
			padding-right: 0 !important;
		}
	}

	&::before {
		content: "";
		position: absolute;
		top: -5vh;
		left: 0;
		height: 10vh;
		width: 100%;
		background-image: url("/images/ill dgroen.svg");
		background-repeat: no-repeat;
		background-position: center;
		z-index: 900;
		background-size: cover;
	}
	&::after {
		content: "";
		position: absolute;
		bottom: -5vh;
		left: 0;
		height: 10vh;
		width: 100%;
		background-image: url("/images/ill lijn bottom lgroen.svg");
		background-repeat: no-repeat;
		background-position: center;
		z-index: 900;
		background-size: cover;
	}
}

.omgeving-section-two {
	background-image: url("/images/omgeving-two-bg-tr.svg");
	background-position: top right -25%;
	background-repeat: no-repeat;
	position: relative;
	.container:nth-child(1) {
		margin: 6vh auto;
	}
	.subtitle {
		font-family: $font-family-secondary;
	}

	.title,
	.subtitle {
		font-size: 36px;
		color: $white !important;
	}
	.container-fluid {
		.column {
			padding-right: 0 !important;
		}
	}
	&::after {
		content: "";
		position: absolute;
		transform: translateY(-50%);
		left: 0;
		top: 0;
		height: 160px;
		width: 100%;
		background-image: url("/images/ill lijn dblauw.svg");
		background-repeat: no-repeat;
		z-index: 900;
		background-position: center;
		background-size: 100vw;
		@include media-breakpoint-down(md) {
		}
	}
	&::before {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		height: 6vh;
		width: 100%;
		background-image: url("/images/ill top2 wit.svg");
		background-repeat: no-repeat;
		background-position: top;
		background-size: cover;
	}
	.owl-dots {
		margin-bottom: 2rem;
	}
	img {
		box-shadow: $shadow;
	}
}

.aanbieding-section {
	background-image: url("/images/aanbiedingen-bg-tl.svg");
	background-position: top left -25%;
	background-repeat: no-repeat;
	.subtitle {
		font-weight: 200;
		font-family: $font-family-secondary !important;
	}
	.title {
		color: $blue-dark !important;
	}
	.title,
	.subtitle {
		font-size: 36px;
	}
	.center-text {
		text-align: center;
		@include media-breakpoint-down(md) {
			text-align: left;
		}
	}
}
