.faciliteiten-slider {
	.owl-item:nth-child(odd) {
		transform: translateY(5%);
		margin-bottom: 1rem;
	}
	.card-title {
		color: $white !important;
		font-size: 18px;
	}
	.card-subtitle {
		color: $white !important;
	}
	.card-description {
		color: $white !important;
	}

	.owl-nav {
		display: none;
	}
	.owl-dots {
		padding-top: 3vh;
		.owl-dot span {
			border-color: $white !important;
		}
	}
	.owl-dots .owl-dot.active span {
		background: $white !important;
	}
}
