// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.25s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// default-card
.default-card {
}

h6,
h5,
h4,
h3,
h2,
h1 {
	font-family: $font-family-primary;
}

.subtitle {
	font-size: 24px !important;
}

#redirect-locale-modal-flags {
	display: flex;
	flex-flow: row wrap;
	column-gap: 15px;
}

& {
	&::after {
		opacity: 0;
		transition: 0.5s ease;
		transform: translateY(-100%);
	}
	&.hide {
		position: relative;
		&::after {
			content: "";
			top: 0;
			left: 0;
			background: #000;
			width: 100%;
			height: 100%;
			position: fixed;
			z-index: 900;
			opacity: 0.7;
			transform: translateY(0%);
			transition: 0.5s ease;
		}
	}
}
